import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: '',
    isTitle: true,
    accessControlList: [ 'Business', 'Branch_Manager' ],
  },
  // {
  //   label: 'Dashboard',
  //   icon: 'bar-chart-2',
  //   link: '/',
  // },
  {
    label: 'Activity',
    icon: 'activity',
    link: '/',
    accessControlList: [ 'Business', 'Branch_Manager' ],
  },
  {
    label: 'Branches',
    icon: 'git-branch',
    link: '/branches',
    accessControlList: [ 'Business' ],
  },
  {
    label: 'Scanners',
    icon: 'smartphone',
    link: '/scanners',
    accessControlList: [ 'Business'],
  },
  {
    label: 'Customers',
    icon: 'user',
    link: '/customers',
    accessControlList: [ 'Business', 'Branch_Manager' ],
  },
  {
    label: 'Employees',
    icon: 'users',
    link: '/employees',
    accessControlList: [ 'Business', 'Branch_Manager' ],
  },
  {
    label: 'Branch Managers',
    icon: 'users',
    link: '/branch-managers',
    accessControlList: [ 'Business' ],
  },
  // {
  //   label: 'Capacity Plan',
  //   icon: 'box',
  //   link: '/capacity-plan',
  // },
  // {
  //   label: 'Monitoring Point',
  //   icon: 'map-pin',
  //   link: '/monitoring-point',
  // },
  // {
  //   label: 'Resources',
  //   icon: 'file-text',
  //   link: '/resources',
  // },
  // {
  //   label: 'Incident Management',
  //   icon: 'alert-triangle',
  //   link: '/scanners',
  // },
  // {
  //   label: 'Billing',
  //   icon: 'dollar-sign',
  //   link: '/other/pages-pricing',
  // },
  // {
  //   label: 'Reports',
  //   icon: 'printer',
  //   subItems: [
  //     {
  //       label: 'Sample',
  //       link: '/other/pages-invoice',
  //     },
  //     {
  //       label: 'Workplace COVID-19 Prevention and Control Compliance Report',
  //       link: '/reports/compliance-report',
  //     },
  //     {
  //       label: 'Daily Health Symptoms Questionaire',
  //       link: '/reports/questionaire',
  //     },
  //   ],
  // },
];
