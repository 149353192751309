export class User {
    id?: string;
    password: string;
    email: string;
    controlCode: string;
    type: string;

    firstName?: string;
    lastName?: string;
    username?: string;
    jwt?: string;
}
