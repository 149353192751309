import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as easyid from 'easyid';
import { environment } from 'src/environments/environment';

import { BusinessProfile } from '../models/business-profile.model';
import { UserProfile } from '../models/user-profile.model';
import { User } from '../models/user.model';
import { CookieService } from '../services/cookie.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(localStorage.getItem('currentUser'));
        }
        return this.user;
    }

    public get headers() {
      const jwt = this.currentUser().jwt;
      return {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
    }

    private saveUser(user: User) {
      localStorage.setItem('currentUser', JSON.stringify(user));
    }

    public saveUserProfile(userProfile: UserProfile) {
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
    }

    public get userProfile(): UserProfile {
      const userProfile = JSON.parse(localStorage.getItem('userProfile'));
      if (userProfile) {
        return userProfile;
      }

      return null;
    }

    public businessProfile(): BusinessProfile {
      return JSON.parse(localStorage.getItem('businessProfile')) as BusinessProfile;
    }

    public saveBusinessProfile(businessProfile: BusinessProfile) {
      localStorage.setItem('businessProfile', JSON.stringify(businessProfile));
    }

    public get businessAddress() {
      const businessProfile = this.businessProfile();
      return `${businessProfile.street}, ${businessProfile.barangay}, ${businessProfile.city}, ${businessProfile.province}`;
    }

    private generateControlCode(): string {
      return easyid.generate({ length: 4, groups: 2 });
    }

    async register(businessProfile: BusinessProfile) {
      try {
        const user: User = new User();
        user.username = businessProfile.email;
        user.email = businessProfile.email;
        user.password = businessProfile.password;
        user.type = environment.user.type;
        user.controlCode = this.generateControlCode();

        delete businessProfile.password;

        const newUser = await this.http.post(environment.api.url + '/auth/local/register', user).toPromise() as { jwt: string, user: User };
        businessProfile.user = newUser.user.id;
        this.saveUser({ ...newUser.user, jwt: newUser.jwt });

        let newBusinessProfile = null;
        const businessProfileUrl = environment.api.url + '/business-profiles';
        if (user.type === 'Business') {
          newBusinessProfile = await this.http.post(businessProfileUrl, businessProfile, this.headers).toPromise() as BusinessProfile;
        } else if (user.type === 'Branch_Manager') {
          const userProfileUrl = environment.api.url + '/user-profiles?user=' + user.id;
          const userProfile = await this.http.get(userProfileUrl, this.headers).toPromise() as UserProfile;
          newBusinessProfile = await this.http.post(`${businessProfileUrl}/${userProfile.business_profile['id']}`, businessProfile, this.headers).toPromise() as BusinessProfile;
          this.saveUserProfile(userProfile);
        }

        this.saveBusinessProfile(newBusinessProfile);


        return newBusinessProfile;
      } catch (error) {
        throw error;
      }
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    async login(email: string, password: string) {
      const response = await this.http.post<any>(environment.api.url + '/auth/local', { identifier: email, password }).toPromise();
      const user: User = { ...response.user, jwt: response.jwt };
      console.log('🚀 ~ file: auth.service.ts ~ line 90 ~ AuthenticationService ~ login ~ user', user);

      if (user && user.jwt) {
        console.log('LOG ~ file: auth.service.ts ~ line 117 ~ AuthenticationService ~ login ~ user', user);
        this.user = user;
        this.saveUser(user);


        let businessProfilePromise = null;
        if (user.type === 'Business') {
          businessProfilePromise = this.http.get(environment.api.url + '/business-profiles?user=' + user.id, this.headers).toPromise();
        } else if (user.type === 'Branch_Manager') {
          const userProfileUrl = environment.api.url + '/user-profiles?user=' + user.id;
          const userProfile = await this.http.get(userProfileUrl, this.headers).toPromise() as UserProfile[];
          console.log('LOG ~ file: auth.service.ts ~ line 128 ~ AuthenticationService ~ login ~ userProfile', userProfile);
          businessProfilePromise = this.http.get(environment.api.url + '/business-profiles?id=' + userProfile[0].business_profile['id'], this.headers).toPromise();
          await this.saveUserProfile(userProfile[0]);
        }
        const businessProfile = await businessProfilePromise as BusinessProfile;
        console.log('LOG ~ file: auth.service.ts ~ line 132 ~ AuthenticationService ~ login ~ businessProfile', businessProfile[0]);

        this.saveBusinessProfile(businessProfile[0]);
      }
      return user;

      // return this.http.post<any>(environment.api.url + '/auth/local', { identifier: email, password }).pipe(
      //   map(response => {
      //     const user: User = { ...response.user, jwt: response.jwt };
      //     // login successful if there's a jwt token in the response
      //     if (user && user.jwt) {
      //         this.user = user;
      //         this.saveUser(user);
      //     }
      //     return user;
      //   })
      // );
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('businessProfile');
        localStorage.removeItem('userProfile');
        this.user = null;
    }
}

